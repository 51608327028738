<template>
    <div class="mainWrapper pb-0">
        <section class="blockElement space position-relative reatesPage">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="flex-between">
                        <h1 class="mb-2 f-30 bold">{{$t('privacy_policy.privacycontent15a')}}</h1>
                    </div>
                </div>
                <div class="col-12">
                    <div class="static">
                        <h3>{{$t('privacy_policy.privacycontent15')}} </h3>
                        <p>{{$t('privacy_policy.privacycontent16')}} </p>
                        <p>{{$t('privacy_policy.privacycontent17')}} </p>
                        <p>{{$t('privacy_policy.privacycontent18')}}</p>
                        <h5>{{$t('privacy_policy.privacycontent19')}} </h5>
                        <p>{{$t('privacy_policy.privacycontent20')}}</p>
                        <p>{{$t('privacy_policy.privacycontent21')}} </p>
                        <p>{{$t('privacy_policy.privacycontent22')}}</p>
                        <h5>{{$t('privacy_policy.privacycontent23')}}</h5>
                        <p>{{$t('privacy_policy.privacycontent24')}} <a href="https://www.allaboutcookies.org/" class="link" target="_blank">www.allaboutcookies.org</a> </p>
                        <h5>{{$t('privacy_policy.privacycontent25')}} </h5>
                        <p>{{$t('privacy_policy.privacycontent26')}} </p>
                        <p>{{$t('privacy_policy.privacycontent27')}}</p>
                        <p>{{$t('privacy_policy.privacycontent28')}}</p>
                        <p>{{$t('privacy_policy.privacycontent29')}} </p>
                        <p>{{$t('privacy_policy.privacycontent30')}}</p>
                        <h5>{{$t('privacy_policy.privacycontent31')}} </h5>
                        <p>{{$t('privacy_policy.privacycontent32')}} </p>
                        <h5>{{$t('privacy_policy.privacycontent33')}} </h5>
                        <p>{{$t('privacy_policy.privacycontent34')}} </p>
                        <p>{{$t('privacy_policy.privacycontent35')}} <a href="https://www.allaboutcookies.org/" class="link" target="_blank">www.allaboutcookies.org</a></p>
                        <p>{{$t('privacy_policy.privacycontent36')}} <a href='mailto:support&#64;zulutrade.com' class='link'>{{$t ('privacy_policy.privacycontent36a')}}</a> </p>
                        <h5>{{$t('privacy_policy.privacycontent37')}} </h5>
                        <p>{{$t('privacy_policy.privacycontent38')}} <a href='/support' rel='nofollow' class='link'>{{$t('privacy_policy.privacycontent38a')}}</a></p>
                    </div>
                </div>
            </div>
        </div>
        </section>
    </div>
</template>